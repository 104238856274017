:root{--h:250px;--v:10px}
[class*='col-']{padding-right:0;padding-left:0;}
.row{margin-right: 0px !important;margin-left: 0px !important;}

@font-face{font-family: AllianzNeo-Regular;src: url(../fonts/AllianzNeo/AllianzNeo-Regular.otf);}

h1,h2,h3,h4,h5,h6{color: #000000;}
body{font-family:AllianzNeo-Regular;background:#fff;color:#2e2e2e}
h1,h2,h3,h4,h5,h6{}
b,strong{font-weight:700}
a:hover{cursor:pointer;}
a{text-decoration: none;}

#text{/* transition: 0.1s ease; */}
.mobile{display: none;}
.desktop{display: block;}
/*Container CSS Starts*/
@media (min-width: 1370px)
{
    .container-large{max-width: 1360px;}
}
@media (min-width: 1200px)
{
    .container-big{max-width: 1200px;}
}
@media (min-width: 990px)
{
    .container-small{max-width: 850px;}
}
/*Container CSS Ends*/

.color-red{color: #FF0000 !important;}

/*Buttons CSS Starts*/
.btn{font-size: 24px;padding: 14px 52px 14px 44px;border: 0px;border-radius: 4px;transition: all 0.3s ease-in;}
.btn span{position:relative;display:flex;flex-wrap:wrap;flex-direction:row}
.btn-dark{background-color: #000000;}
.btn-white{color: #013F87 !important;background-color: #ffffff;}
.btn-blue{color: #ffffff !important;background-color: #013F87;}
.btn span:after{content:'';background-image:url(../images/arrows--diagrams-arrow@2x.svg);width:27px;height:24px;background-size:cover;background-position:center center;margin-left:21px;transform:translateY(7px);background-repeat:no-repeat;transition: all 0.3s ease-in;}
.grey-btn span:after{content:'';background-image:url(../images/Arrow-grey.svg);width:27px;height:24px;background-size:cover;background-position:center center;margin-left:21px;transform:translateY(7px);background-repeat:no-repeat;transition: all 0.3s ease-in;}
.btn-blue span:after{background-image: url("../images/arrows--diagrams-arrow-2@2x.svg");}

.popup-btn .btn{font-size:16px;padding:10px 34px 10px 29px}
.popup-btn .btn span:after{width:13px;height:9px;margin-left:20px;transform:translateY(7px)}

.btn-link{font-size: 20px;color: #013F87;padding: 14px 0px !important;}
.btn-link span:after{}

.btn:hover span:after
{transform: translateY(7px) translateX(20px) !important;}
.profile-card-btn-outer .btn:hover span:after
{transform: translateY(3px) translateX(12px) !important;}
/*Buttons CSS Ends*/


/*Gradient Text CSS Starts*/
/*.gradient-text{background: linear-gradient(to right, #0F3D95 0%, #009DE1 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;}*/
/*Gradient Text CSS Ends*/

/*Title Section CSS Starts*/
.title-section{}
.section-title{}
.section-title h2{font-size: 60px;line-height: 85px;color: #013F87;}
.section-title h4{font-size: 40px;line-height: 85px;color: #013F87;}
.section-title h2 b{color: #AB768A;font-weight: 400 !important;}
.section-title p{font-size: 20px;line-height: 35px;color: #4E4E4E;}
.section-title-madrid{font-size: 24px;}
/* .section-title span{font-weight: 600;} */
/* .section-title p{font-size: 32px;line-height: 46px;color: #4E4E4E;} */
.title-section-small{max-width: 910px;display: block;margin: auto;}
.section-title h2 span.title-left-space{}
.section-title h3{font-size: 40px;line-height: 57px;color: #013F87;}
.section-title-blue h2 b{color: #013F87;}
.section-title-blue h2 span{color: #4E4E4E;}
.section-title-red h2 b{color: #AB768A;}
.section-title-red h2 span{color: #4E4E4E;}
.about-top-section.inner-top-section .section-title.section-title-normal h2{margin-left: 0px;text-align: center;}
/*Title Section CSS Ends*/


/*Header CSS Starts*/
/* header{padding-top: 32px;padding-bottom: 40px;} */
header{padding-top: 32px;padding-bottom: 40px;
    overflow: hidden;
    background-color: #FFF;
    transition: 0.4s;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 99;}

.navbar{padding: 0px;}
header ul.navbar-nav{padding-left: 260px;}
header ul.navbar-nav li{margin: 0px 25px;}
header ul.navbar-nav li a{font-size: 24px;line-height: 29px;color: #000;transition: all 0.3s ease-in;position: relative;}
header ul.navbar-nav li .active{color: #013F87;}
header ul.navbar-nav li:first-child{margin-left: 0px;}
header ul.navbar-nav li:last-child{margin-right: 0px;}
header ul.navbar-nav li a:before
{content: '';width: 100%;height: 2px;position: absolute;bottom: 0;left: 0;background-color: transparent;transition: all 0.4s ease-in;}
header ul.navbar-nav li a:hover::before
{background-color: #013F87;}
header ul.navbar-nav li a:hover{color: #013F87 !important;}
/*Header CSS Ends*/

/*Inner page Banner CSS Starts*/
.inner-page-banner{}
.inner-page-banner img{width: 100%;object-fit: cover; }
.inner-page-banner-new img{width: 100%;object-fit: cover; height: 68vh;}
.inner-page-banner-news img{height: 56vh;}
/*Inner page Banner CSS Ends*/

/*Popup Section CSS Starts*/
/* .popup-section{position: fixed;top: 0;left: 0;width: 100%;height: 100vh;padding-top: 10%;background-color: rgba(0,0,0,0.7);z-index: 99;} */
/* .popup-inner{max-width:938px;width:100%;display:block;margin:auto;padding:40px 60px 112px 60px;background-image:url(../images/Allianz_xuan_lan.png);background-size:cover;background-position:center center;position:relative;margin:0 auto;position:absolute;top:50%;-ms-transform:translateY(-50%);transform:translateY(-50%);left:0;right:0} */
.popup-section{margin-top: 60px;}
.custom-popup{position: fixed;left: 10%;width: 100%;top:20px;margin-top: 20px;z-index: 9999999;}
.popup-inner{width:100%;display:block;margin:auto;padding:40px 60px 112px 60px;background-image:url(../images/popup-image-des.png);background-size:cover;background-position:center center;position:relative;margin:0 auto;}
.custom-popup>.popup-inner{width:80%;display:block;margin:auto;padding:40px 60px 112px 60px;background-image:url(../images/cabecera.png);background-size:cover;background-position:center center;position:absolute;margin:0 auto;}
.popup-inner:before{content: '';width: 100%;height: 100%;position: absolute;top: 0;left: 0;background-color: rgba(0,0,0,0.1);}
.popup-close{position: absolute;top: 50px;right: 60px;}
.popup-close a{}
.popup-close img{}
.popup-content{max-width: 560px;margin-top: 26px;position: relative;}
.popup-title{}
.popup-title h2,.popup-desc p{color: #ffffff;}
.popup-title h2{font-size: 40px;line-height: 56px;font-weight: 700;}
.popup-desc{margin-top: 26px;}
.popup-desc p{font-size: 16px;line-height: 23px;}
.popup-btn{margin-top: 37px;}
.popup-btn .btn{}

.popup-section-new
{}
.popup-section-new .popup-content {
    max-width: 520px;
}
.popup-section-new .popup-desc {
    margin-top: 0px;
}
.popup-section-new .popup-title h2
{font-size: 60px;line-height: 85px;font-weight: 400;}
.popup-section-new .popup-desc p
{font-size: 24px;line-height: 34px;}
.popup-section-new .popup-desc p a
{font-weight: 800;color:#FFF}
.custom-width{width:80%; margin-left:auto;margin-right:auto}
/*Popup Section CSS Ends*/

/*Cookies Popup Section CSS Starts*/
.cookies-popup-section{width: 100%;background-color: #013F87;position: fixed;bottom: 0;left: 0;z-index: 99;}
.cookies-inner{padding: 18px 0px;}
.cookies-popup-left{}
.cookies-popup-right{}
.cookies-content{}
.cookies-content p{font-size: 13px;line-height: 20px;color: #ffffff;margin: 0px;}
.cookies-popup-btn-outer{text-align: right;}
.cookies-popup-btn-outer a{font-size: 12px;font-weight: 700;padding: 8px 10px;border-radius: 2px;}
.cookies-link{color: #ffffff;text-decoration: underline;margin-right: 10px;}
.cookies-btn{background-color: #ffffff;color: #013F87;}
/*Cookies Popup Section CSS Ends*/

/*Banner Section CSS Starts*/
.banner-section{padding: 30px 0px 415px 0px;background-color: #ddd;background-image: url("../images/bg-slider.png");background-size: cover;background-position: top right;}
/* .banner-section-home{height: 105vh;} */
.banner-section-home{height: 55vh;}
.banner-inner{}
.banner-title{}
.banner-title h1{font-size: 60px;line-height: 85px;color: #013f87;}
.banner-title-home h1{font-size: 60px;line-height: 85px;color: #FFF;}
.banner-title h1 span{color: #ab768a;}
.banner-title-home h1 span{color: #FFF;}
.btn-banner{margin-top: 30px;}
.btn-banner .btn{}
/*Banner Section CSS Ends*/

/*About Top Section CSS Starts*/
.about-top-section{padding-top: 60px;}
.about-top-section .section-title p{margin-top: 40px;}
.about-top-section .section-title h2{text-align:left;margin-left:20px}
.about-top-section .section-title h2 span{margin-left:49px}
.about-top-section .section-title h2 i{margin-left:49px;font-style: normal;}
.inner-top-section{}
.btn-title{margin-top: 55px;}
.inner-top-section{}
.about-top-section.inner-top-section .section-title h2{margin-left:255px}
.about-top-section.inner-top-section .section-title h2 span{margin-left:85px}
/*About Top Section CSS Ends*/

/*Content Section CSS Starts*/
.content-section{padding-top: 36px;padding-bottom: 36px;}
.content-img-left{}
.content-img-right{}

.content-left{padding-right: 42px;position: relative;}
.content-left-Ibiza{padding-right: 75px;position: relative;}
.padding-right-0{padding-right: 0px;}
.content-right{position: relative;}
.content-img{position: relative;}
.content-img .pic{width: 100%;height: 360px;object-fit: cover;}
.content-img-madrid .pic{width: 100%;height: 240px;object-fit: contain;}
.content-img-madrid .pic-Ibiza{object-fit: contain;height: 610px;width: 100%;}
.content-img-right .content-img .pic{object-position:28% center}
.content-text{}
.content-title{}
.content-title h3{font-size: 32px;line-height: 46px;color: #013F87;}
.content-desc{margin-top: 20px;}
.padding-right{padding-right:20px}
.content-desc p{font-size: 24px;line-height: 34px;}
.icon-play{width:70px;height:70px;position:absolute;top:0;bottom:0;left:0;right:0;display:block;margin:auto}
.icon-play img{width:70px;height:70px}
.icon-live{width:100px;height:auto;align-items:center;background-color:#f00000;border-radius:65px;display:flex;left:40px;overflow:hidden;padding:4px 14px;position:absolute;top:32px}
.live-circle{width:10px;background-color:#fff;border-radius:5px;height:10px;margin-top:-1px}
.live-text{width:48px;height:29px;font-size:24px;letter-spacing:0;line-height:28px;margin-left:10px;white-space:nowrap;color:#fff}
.btn-content{margin-top:40px}
.inr-content-section{padding-top:70px;padding-bottom:36px}
.inr-content-section-1{}
.inr-content-section-2{}
.inr-content-section-2 .content-img img{height: 360px;object-fit: cover;}
.inr-content-section-2 .content-img-sevilla img{height: 280px;object-fit: cover;}
.inr-content-section-2 .content-img-Ibiza img{height: 400px;object-fit: cover;}
.inr-content-section-2 .content-img-Ibiza1 img{height: 550px;object-fit: cover;}
.inr-content-outer{margin-top: 30px;}
.inr-content-section .content-title h3{font-size:32px;line-height:57px;color:#070707}
.inr-content-section .content-title-sevilla h3{font-size:32px;line-height:0px;color:#070707}
.inr-content-section .content-title-sevilla .Ibiza-head-right{font-size:32px;line-height:40px;color:#070707}
.inr-content-section .content-title-madrid h3{font-size:32px;color:#070707;margin-bottom: 0px !important;}
.inr-content-section .btn-content{margin-top:125px}
.content-icon-section{margin-top:40px}
.content-icon-section p{font-size:18px;line-height:26px}
.content-icon-section p img{transform: translateY(-3px);margin-right: 10px;}
.madrid-big-video img{width: 100%;}
/*Content Section CSS Ends*/

/*Post Section CSS Starts*/
.btn-disabled{
    background-color: #CCC !important;
    pointer-events: none;
}
.link-disabled{
    pointer-events: none;
}
.post-section{padding-top: 100px;}
.post-inner{}
.post-box-outer{margin-top: 70px;margin-left: -7px;margin-right: -7px;}
.post-box{padding: 7px;}
.post-box-inner{background: #BDBDBD;overflow: hidden;position: relative;}
.post-box-img{}
.post-box-img img{width: 100%;height: 290px;object-fit: cover;filter: blur(50px) !important;-webkit-filter: blur(50px) !important;-webkit-backdrop-filter: blur(50px) !important;backdrop-filter: blur(50px) !important;}
.post-box-content{position:absolute;left:0;bottom:0;width:100%;height:100%;padding:25px;display:flex;flex-wrap:wrap;flex-direction:row;align-items:flex-end;background: linear-gradient(0deg, #000000 -6.12%, rgba(0, 0, 0, 0) 43.88%);}
.post-box-content h4{font-size: 24px;line-height: 40px;text-align: center;color: #ffffff;margin: 0px;}
.original-text{width: 100%;transition: all 0.3s ease-in;}
.hover-text{display: none;width: 100%;transition: all 0.3s ease-in;}
.hover-text h4{font-size: 24px;}
.post-box-inner:hover .post-box-content{align-items: center;cursor: pointer;background: linear-gradient(0.99deg, #000000 0.93%, rgba(0, 0, 0, 0) 99.24%);}
.post-box-inner:hover .original-text{display: none;}
.post-box-inner:hover .hover-text{display: block;text-align: center;}
.btn-post{margin-top: 52px;}
.post-box.active .post-box-img img{filter: blur(0px) !important;-webkit-filter: blur(0px) !important;-webkit-backdrop-filter: blur(0px) !important;backdrop-filter: blur(0px) !important;}
.small-text{margin-top: 24px;}
.small-text p{font-size: 16px;line-height: 20px;color: #013F87;}
.small-text p a{color: inherit;}
/*Post Section CSS Ends*/

/*Profile Card Section CSS Starts*/
.profile-card-section{padding-top: 65px;}
.profile-card-left{padding-right: 10px;}
.profile-card-right{padding-left: 30px;}
.profile-card-left-inner{position: relative;}
.profile-card-right-inner{}
.profile-card-img{position: relative;}
.profile-card-img img{width: 100%;height: 352px;object-fit: cover;}
.profile-card-img h2{font-size:49px;line-height:69px;color:#fff;position:absolute;bottom:20px;text-align:center;width:100%}
.profile-card-title{}
.profile-card-title h2{font-size: 80px;line-height: 113px;color: #383838;font-weight: bold;}
.profile-card-content{margin-top: 5px;}
.profile-card-content p{font-size: 24px;line-height: 34px;color: #4E4E4E;}
.profile-card-content-incription-bottom p{font-size: 32px;line-height: 34px;color: #4E4E4E;}
.profile-card-btn-outer{margin-top: 26px;}
.profile-card-btn-left{}
.profile-card-btn-right{}
.profile-card-btn-outer .btn{font-size:20px;padding:14px 30px 14px 30px}
.profile-card-btn-outer .btn span:after{margin-left:12px;transform:translateY(3px)}
/*Profile Card Section CSS Ends*/

.bs-text-section{padding-bottom: 100px;}
.content-text{margin-top: 80px;}
.content-text-ibz{margin-top: 0px;}
.content-text h3{font-size: 32px;line-height: 46px;color: #013F87;margin-bottom: 24px;}
.content-text p{font-size: 20px;line-height: 35px;}
.content-text span{font-size: 20px;line-height: 35px;color: #013F87;}

/*Footer CSS Starts*/
.footer-section{}
.footer-inner{}
.footer-cta{}
.footer-cta-inner{padding-top: 100px;padding-bottom: 65px;border-bottom: 1px solid #B3B3B3;}
.footer-cta-title{}
.footer-cta-title h2{font-size: 60px;color: #ab768a;}
.footer-subtitle{}
.footer-subtitle p{font-size: 32px;color: #013f87;}
.footer-quote{padding-top: 60px;padding-bottom: 80px;}
.footer-quote-inner{}
.footer-quote-left{}
.footer-quote-right{padding-left: 60px;}
.footer-quote-img{}
.footer-quote-img img{}
.footer-quote-text{}
.footer-quote-text p{font-size: 24px;color: #4e4e4e;margin: 0px;}
.footer-box p,.footer-box a,.footer-copy p{color: #4E4E4E;text-decoration: none;}
.footer-box-section{padding-top: 50px;padding-bottom: 50px;background-color: #F5F5F5;}
.footer-box-outer{}
.footer-box{}
.footer-box-inner{}
.footer-box p{}
.footer-logo{}
.footer-logo img{}
.footer-menu{}
.footer-menu ul{margin: 0px;padding: 0px;}
.footer-menu ul li{list-style: none;margin-bottom: 20px;}
.footer-menu ul li a{}
.footer-menu ul li a:hover{color: #007BB7;}
.footer-social{}
.footer-social ul{margin: 0px;padding: 0px;display: flex;flex-wrap: wrap;flex-direction: row;}
.footer-social ul li{list-style: none;margin-right: 8px;}
.footer-social ul li a{}
.footer-social ul li.footer-social-name
{width: 110px;text-align: right;}
.footer-copy{}
.footer-copy p{margin: 0px;}
/*Footer CSS Ends*/
.hover-cursor:hover{
    cursor: pointer;
}
.my-form{
    width: 100% !important;
    height: 500px;
}
.color-dark{color:#4E4E4E !important}
.color-blue{color: #013f87 !important;}
.color-blue1{color: #223574 !important;}
.section-title .f-32{font-size: 32px;}
.line-h45{line-height: 45px!important;}
.line-h45 span{color: hsl(337, 24%, 57%);}
.top-90{margin-top: 90px;}
.popup-desc .text-20{font-size: 20px;}
.grey-btn{color: #B7B7B7;}
.grey-btn span::after{color: #B7B7B7;}
.grey-btn:hover{color:#B7B7B7;cursor: default;} 
.width-80{width: 80%;margin-left: auto;margin-right: auto;}
.footer-logo-left{text-align: right;padding-right: 40px;}
.footer-logo-right{text-align: left;padding-left: 30px;}
.numbers-outer{padding: 0px 5px;text-align: left;}
.numbers-outer p{font-size: 24px;}
.circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    background: #013F87;
    line-height:30px;
    margin-bottom: 10px;
}

/*FAQ Section CSS Starts*/
.faq-section{}
.faq-inner{}
.acc-outer{margin-top: 50px;}
.faq-section .card{border: 0px;border-radius: 0px;margin-bottom: 36px;}
.faq-section .card-header{padding:0;background-color:transparent;border:0;border-bottom:1px solid #000;position:relative}
.faq-section .card-header .btn{width:100%;font-size:24px;line-height:48px;color:#111;text-align:left;display:block;padding:20px 34px;padding-right:50px;box-shadow:unset!important;position:relative;text-decoration: none;}
.faq-section .card-header .btn:before{content: '';width: 16px;height: 11px;background-image: url("../images/icon-arrow-down.svg");background-repeat: no-repeat;background-position: center center;position: absolute;top: 40px;right: 34px;}
.card-body{
    text-align: left;
}
.card-body img{}
/*FAQ Section CSS Ends*/

/* Promociones css start */
.fix-padding{padding: 10px;}
.promo-img img{width: 100%;padding: 10px;}

/* Promociones css end */

/*Media Query CSS Starts*/
@media screen and (max-width: 1440px)
{
    header ul.navbar-nav{padding-left:0;margin-left:auto}
}

@media screen and (max-width: 1200px)
{}

@media screen and (max-width: 990px)
{
    header{padding:15px 0;}
    .navbar-collapse{position:fixed;width:100%;height:100vh!important;top:0;left:100%;padding:115px 15px 15px 15px;z-index:99999;background-color:#013f87;transition:all .3s ease-in}
    .navbar-collapse.show{left:0}
    .navbar-toggler{z-index:99999999999;border:0;outline:unset!important;width:26px;height:26px;position:relative}
    .navbar-toggler-icon{display:none}
    .menu-icon{position:absolute;top:0;left:0}
    .navbar-toggler[aria-expanded=false] .toggle-icon{display:block}
    .navbar-toggler[aria-expanded=false] .close-icon{display:none}
    .navbar-toggler[aria-expanded=true] .close-icon{display:block}
    .navbar-toggler[aria-expanded=true] .toggle-icon{display:none}
    header ul.navbar-nav li{margin:0}
    header ul.navbar-nav li a{font-size:44px;line-height:53px;color:#fff}
    .banner-section{padding:80px 0 200px 0}
    .banner-title h1,.section-title h2{font-size:50px;line-height:65px}
    .about-top-section .section-title h2 span{margin-left:136px}
    .about-top-section .section-title h2 i{margin-left:136px}
    .about-top-section.inner-top-section .section-title h2{margin-left:120px}
    .section-title p{font-size:26px;line-height:40px}
    .content-left{padding-right:0}
    .content-right{padding-left:0}
    .content-text{margin-top:20px}
    .inner-page-banner img{height:380px}
    .profile-card-left{padding-right:0}
    .profile-card-right{padding-left:0}
    .footer-quote-right{padding-left:36px}
    .footer-quote-text p{font-size:21px}
}

@media screen and (max-width: 767px)
{
    .mobile{display: block;}
    .desktop{display: none;}
    header{padding-top:16px;padding-bottom:20px}
    .navbar-brand img{height:50px}
    .banner-title h1,.section-title h2{font-size:32px;line-height:46px}
    .content-desc p,.section-title p,.content-icon-section p{font-size:16px;line-height:23px}
    .btn{padding:12px 18px}
    .btn span:after{margin-left:15px}
    .banner-section{padding:24px 0 360px 0;background-position:50% 0}
    .about-top-section .section-title h2{margin-left:0}
    .home-section .section-title h2{margin-left:0;display: contents;}
    .about-top-section.inner-top-section .section-title h2{margin-left:0}
    .about-top-section.inner-top-section .section-title h2 span{margin-left:50px}
    .section-title h3{font-size:30px;line-height:40px}
    .content-icon-section p img{height:32px;margin-right:5px}
    .inr-content-section .btn-content{margin-top:50px}
    .btn-title{margin-top:30px}
    .profile-card-title h2{font-size:50px;line-height:80px}
    .profile-card-content p{font-size:20px;line-height:24px}
    .profile-card-img h2{font-size:42px;line-height:60px}
    .about-top-section .section-title h2 span{margin-left:12px}
    .about-top-section .section-title h2 i{margin-left:0px;font-style: normal;}
    .content-img .pic,.inr-content-section-2 .content-img img{height:321px}
    .content-img .pic,.inr-content-section-2 .content-img-Ibiza1 img{height:381px;width: 100%;}
    .icon-play img{width:56px;height:56px}
    .content-title h3,.inr-content-section .content-title h3{font-size:24px;line-height:34px}
    .icon-live{width:80px;left:30px;top:23px}
    .live-text{font-size:17px;margin-left:7px}
    .live-circle{width:7px;height:7px;margin-top:-2px}
    .post-box-img img{height:360px}
    .post-box-content h4{font-size:47px;line-height:74px}
    .about-top-section .section-title p{margin-top:20px}
    .original-text{padding-bottom:20px}
    .content-section{padding-top:10px;padding-bottom:10px}
    .content-text{margin-top:26px}
    .btn-content{margin-top:30px}
    .post-section{padding-top:30px}
    .post-box-outer{margin-top:30px}
    .post-box{padding:10px 10px}
    .btn-post{margin-top:36px;text-align:center!important}
    .inner-page-banner img{height:200px}
    .popup-inner{width: 100%;padding: 20px 50px 80px 30px;}
    .popup-inner{width:100%;display:block;margin:auto;padding:40px 60px 112px 60px;background-image:url(../images/popup-image-mob.png);background-size:cover;background-position:center center;position:relative;margin:0 auto;}
    .popup-close{right: 30px;}
    .popup-content{margin-top: 18px;}
    .popup-title h2{font-size: 34px;line-height: 50px;}
    .popup-section-new .popup-title h2{font-size: 34px;line-height: 50px;}
    .popup-desc p{font-size: 15px;line-height: 24px;}
    .popup-section-new .popup-desc p{font-size: 15px;line-height: 24px;}
    .cookies-popup-btn-outer{text-align: left;margin-top: 15px;}
    .footer-cta-inner{padding-top:50px;padding-bottom:40px}
    .footer-cta-title h2{font-size:40px;line-height:57px}
    .footer-subtitle p{font-size:30px;margin:0}
    .footer-quote{padding-top:40px;padding-bottom:60px}
    .footer-quote-text{margin-top:50px}
    .footer-quote-text p{font-size:24px;line-height:34px;text-align:center}
    .footer-quote-right{padding-left:0}
    .footer-box-section{padding:30px 20px}
    .footer-box a,.footer-box p,.footer-copy p{font-size:16px;line-height:22px}
    .footer-box .row{justify-content:center}
    .footer-logo img{height:65px}
    .footer-box{margin-bottom:30px}
    .footer-menu ul li:last-child{margin-bottom:0}
    .footer-box-social{text-align:center}
    .footer-box-social ul{justify-content:center}
    .footer-copy{text-align:center}
    .custom-width{width:100%; margin-left:auto;margin-right:auto}
    .custom-popup>.popup-inner{width:100%;}
    .custom-popup{left:0%;margin-top:0px;top:0}
    .width-80{width: 100%;}
    .footer-logo-left{}
    .footer-logo-right{}
    .card-body img{width: 100%;}
    .faq-section .card-header .btn {
        font-size: 22px;
        line-height: 32px;
        padding-left: 0;
        padding-right: 50px !important;
    }
    .faq-section .card-header .btn:before{top:25px;right:10px;}
    header ul.navbar-nav li .active{color: #c1c1c1}
    .content-img-madrid .pic-Ibiza{height: auto;}
    .content-desc-Ibiza{margin-bottom: 30px;}
}
/*Media Query CSS Ends*/
